
new WOW({ offset: 70 }).init();

function loadSliders() {
	$('.clients__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 800,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 450,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});

	$('.home-gallery__inner').slick({
		centerMode: false,
		centerPadding: '0',
		slidesToShow: 4,
		arrows: false,
		autoplay: true,
		autoplaySpeed: 5000,
		responsive: [
			{
				breakpoint: 1000,
				settings: {
					slidesToShow: 2
				}
			},
			{
				breakpoint: 768,
				settings: {
					slidesToShow: 1
				}
			}
		]
	});
}

var sliderScript = document.getElementById("deferredSlickScript");

if (sliderScript) {
	sliderScript.addEventListener('load', function () {
		loadSliders();
	});
}

$(document).ready(function () {

	$('.newsletter__name').attr("placeholder", "Your Name");
	$('.newsletter__email').attr("placeholder", "Your Email Address");
	$('.testimonials .carousel').carousel({ interval: 7500 })

	$("img.has-tooltip").tooltip({
		title: function () {
			return $(this).attr("alt");
		},
		placement: "auto"
	});

	$("a.has-tooltip").tooltip({
		placement: "auto"
	});

	if ($('#HomepageMarker').length > 0) {

		var testimonialAuthors = $(".RotatorTestimonialContent a[itemprop='author']");
		if (testimonialAuthors.length > 0) {
			$(".RotatorTestimonialContent a[itemprop='author']").each(function () {
				$(this)[0].nextSibling.nodeValue = '';
			});
		}

		$('.left-col__logo-panel').addClass("wow fadeInUp");

		Splitting({

		});

	} else {

	}

	function fixHeights() {
		$(".RotatorTestimonialContent").sameHeight();
		$(".featured-categories__title").sameHeight();
	}

	setTimeout(fixHeights, 500);

	$(window).on("resize", function () {
		setTimeout(fixHeights, 500);
	});

});

